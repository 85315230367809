






import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

@Component
export default class ProgressBar extends Vue {
	@Prop({required: true, type: Number}) percentage!: number

	get calcWidth(): number {
		const percentage = 100 - this.percentage
		return percentage > 100 ? 100 : percentage < 0 ? 0 : percentage
	}
}
