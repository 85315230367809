










import Vue from "vue"
import Component from "vue-class-component"

@Component
export default class Welcome extends Vue {
	hours = new Date().getHours()
}
