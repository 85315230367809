











import Vue from "vue"
import Component from "vue-class-component"

@Component
export default class Jumbotron extends Vue {
	hasSubtitle = !!this.$slots.subtitle
}
