
import "whatwg-fetch"
import Index from "pages/Index.vue"
import store from "store/index"
import Vue from "vue"
import {apply as applyLocale} from "lib/i18n/session"

applyLocale()

/* tslint:disable:no-unused-expression */
new Vue({
	components: {
		Index
	},
	el: "#app",
	store,
	template: '<div id="app"><Index v-cloak></Index></div>'
})
