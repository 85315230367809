












import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

import eventBus from "lib/vue/eventBus"

@Component
export default class Highlight extends Vue {
	@Prop({type: String, required: true}) type!: string
	@Prop({type: String, required: false}) url?: string
	@Prop({type: String, required: false}) event?: string

	clicked() {
		if (this.url) {
			location.href = this.url
		} else if (this.event) {
			eventBus.emit(this.event)
		}
	}
}
