
















































































































































































import Vue from "vue"
import Component from "vue-class-component"
import {State, namespace, Getter} from "vuex-class"

import {IconCheckmark, IconFolder, IconGraph, IconEnvelope,
				IconEnvelopeOpened, IconPerson, IconStar, IconSuitcase, IconTarget, IconPiggybank} from "custom/icons"

import Amount from "custom/Amount.vue"
import Highlight from "custom/Highlight.vue"
import Highlights from "custom/Highlights.vue"
import Page from "custom/Page.vue"
import ProgressBar from "custom/ProgressBar.vue"
import Welcome from "custom/Welcome.vue"

import ActionButton from "components/button/ActionButton.vue"
import Badge from "components/supplemental/Badge.vue"
import CardSection from "components/supplemental/CardSection.vue"
import Card from "components/panel/Card.vue"
import Checkbox from "components/form/Checkbox.vue"
import Jumbotron from "components/panel/Jumbotron.vue"
import LinkButton from "components/button/LinkButton.vue"
import List from "components/generic/List.vue"
import Message from "components/widget/Message.vue"
import {WizardItemData} from "components/navigation/Wizard.vue"
import Tooltip from "components/supplemental/Tooltip.vue"

import eventBus from "lib/vue/eventBus"
import event from "lib/vue/event"

import {StoreAction} from "lib/types/vuex"
import {MeubelParticipant} from "store/participant/types"
import {Document, Message as Msg} from "store/communication/types"
import {CustomerJourneys} from "store/customerJourneys/types"

interface PensionProfileStep {
	title: string
	progress: number
	url: string
	percentage: number
}

interface HighlightData {
	title: string
	url?: string
	position: number
	highlightClass: string
	show: boolean
	button?: string
	[key: string]: any
	type?: "primary" | "secondary"
}

const communication = namespace("communication")

@Component({
	components: {
		ActionButton,
		Amount,
		Badge,
		Card,
		CardSection,
		Checkbox,
		Highlight,
		Highlights,
		Jumbotron,
		LinkButton,
		List,
		Message,
		Page,
		ProgressBar,
		Welcome,
		Tooltip,

		// icons
		IconCheckmark,
		IconEnvelope,
		IconEnvelopeOpened,
		IconFolder,
		IconGraph,
		IconPerson,
		IconPiggybank,
		IconStar,
		IconSuitcase,
		IconTarget
	}
})
export default class Index extends Vue {
	@State lastLogin!: Date | null

	@State customerJourneys!: CustomerJourneys
	@State participant!: MeubelParticipant

	@communication.Getter messages!: Array<Msg>
	@communication.State pensionCommunication!: boolean
	@communication.Getter unreadMessages!: number
	@communication.Getter hasUPOUitDienst!: boolean
	@communication.Getter startedEmploymentLast6Months!: boolean

	@communication.Getter documents!: Array<Document>
	@communication.Action downloadDocument!: StoreAction<Document>
	@communication.Getter unreadDocuments!: number

	@Getter("investments/total") investmentsTotal!: number
	@Getter("pensiongoal/total") pensiongoalTotal!: number
	@Getter("pensiongoal/isEdited") pensiongoalEdited!: boolean
	@Getter("pensiondetails/isEdited") pensiondetailsEdited!: boolean
	@Getter("pensiondetails/totalMonthlyNet") totalMonthlyNet!: number

	loaded = false

	async beforeMount() {
		await event("loaded", 5000)
		this.loaded = true
	}

	locale = (text: string) => Vue.filter("locale")(text)

	readMessage(message: Msg) {
		eventBus.emit("messageModal", message)
	}

	constructUrl(url?: string) {
		return !process.env.SERVER && url
		? `${url}?person=${this.participant.firstName}`.toLowerCase()
		: url
	}

	journeyStatus(journey: string): string {
		if (this.loaded) {
			if (this.customerJourneys[journey]) {
				return this.customerJourneys[journey].status
			}
		}
		return "pending"
	}

	get riskProfileProgress(): number {
		if (this.journeyStatus("riskprofile") === "pending") { return 0 }
		if (this.journeyStatus("riskprofile") === "completed") { return 100 }
		const steps = this.customerJourneys.riskprofile.steps
		if (steps) {
			const items = steps.questions.data.navigationData.filter((item: WizardItemData) => item.complete).map((item: WizardItemData) => item.code)
			return items.includes("outro") ? 100
				: items.includes("income")
					? (100 / 35) * 20
					: (100 / 35) * 10
		}
		return 0
	}

	get visibleHighlights(): Array<HighlightData> {
		return !this.loaded ? [] : this.highlights.reduce((acc, x) => x.show
			&& acc.every(i => i.position !== x.position) ? [...acc, x] : acc, [] as Array<HighlightData>)
	}

	get pensionProfileSteps(): Array<PensionProfileStep> {
		return [
			{
				title: this.locale("index.pensionProfile.riskprofile"),
				progress: this.riskProfileProgress,
				url: "riskprofile.html",
				percentage: 35
			}, {
				title: this.locale("index.pensionProfile.variablepension"),
				progress: this.journeyStatus("variablepension") === "completed" ? 100 : 0,
				url: "variablepension.html",
				percentage: 15
			}, {
				title: this.locale("index.pensionProfile.pensiongoal"),
				progress: this.pensiongoalEdited ? 100 : 0,
				url: "pensiongoal.html",
				percentage: 15
			}, {
				title: this.locale("index.pensionProfile.otherincome"),
				progress: this.pensiondetailsEdited ? 100 : 0,
				url: "pensiondetails.html",
				percentage: 15
			}
		]
	}

	get pensionProfileProgress(): number {
		return this.pensionProfileSteps.reduce((acc, step) => acc + (step.progress / 100) * step.percentage, 0)
				+ (100 - this.pensionProfileSteps.reduce((acc, step) => acc + step.percentage, 0))
	}

	get pensionProfileStepsToDo(): Array<PensionProfileStep> {
		return this.pensionProfileSteps.filter(step => step.progress !== 100)
	}

	get highlights(): Array<HighlightData> { return [
			{
				title: "index.highlights.10",
				url: "riskprofile.html",
				position: 1,
				highlightClass: "highlight__bg highlight_riskprofile",
				button: "Start",
				show: this.journeyStatus("riskprofile") === "pending"
			}, {
				title: "index.highlights.14",
				url: "riskprofile.html",
				position: 2,
				highlightClass: "highlight__bg highlight_riskprofile",
				button: "Ga verder",
				show: this.journeyStatus("riskprofile") === "started"
			}, {
				title: "index.highlights.18",
				url: "riskprofile.html",
				position: 2,
				highlightClass: "highlight__bg highlight_riskprofile",
				show: false
			}, {
				title: "index.highlights.20",
				url: "variablepension.html",
				position: 2,
				highlightClass: "highlight__bg highlight_variablepension",
				button: "Start",
				show: this.journeyStatus("riskprofile") === "completed" && this.journeyStatus("variablepension") === "pending"
			}, {
				title: "index.highlights.24",
				url: "variablepension.html",
				position: 2,
				highlightClass: "highlight__bg highlight_variablepension",
				show: this.journeyStatus("riskprofile") === "completed" && this.journeyStatus("variablepension") === "started",
				button: "Ga verder"
			}, {
				title: "index.highlights.28",
				url: "variablepension.html",
				position: 2,
				highlightClass: "highlight__bg highlight_variablepension",
				show: false
			}, {
				title: "index.highlights.30",
				url: "pensiongoal.html",
				position: 2,
				highlightClass: "highlight__bg highlight_pensiongoal",
				button: "Start",
				show: !this.pensiongoalEdited
			}, {
				title: "index.highlights.40",
				url: "pensiondetails.html",
				position: 3,
				highlightClass: "highlight__bg highlight_pensiondetails",
				button: "Start",
				show: this.pensiongoalEdited && !this.pensiondetailsEdited
			}, {
				title: "index.highlights.45",
				url: "pensiondetails.html",
				position: 3,
				highlightClass: "highlight__bg highlight_pensiondetails",
				button: "Ga verder",
				show: false
			}, {
				title: "index.highlights.50",
				url: "valuetransfer.html",
				position: 3,
				highlightClass: "highlight__bg highlight_valuetransfer",
				button: "Start",
				show: this.journeyStatus("valuetransfer") !== "completed"
			}, {
				title: "index.highlights.60",
				url: "pensiongoal.html",
				button: "Start",
				highlightClass: "highlight__bg highlight_pensiongoal",
				position: 3,
				show: this.pensiongoalTotal > this.totalMonthlyNet
			}, {
				title: "index.highlights.70",
				url: "planner.html",
				position: 4,
				button: "Start",
				highlightClass: "highlight__bg highlight_planner",
				show: true
			}, {
				title: "index.highlights.80",
				url: "myinvestments.html",
				position: 5,
				highlightClass: "highlight__bg highlight_investments",
				show: true
			}, {
				title: "index.highlights.90",
				event: "profileModal",
				position: 6,
				highlightClass: "highlight__bg highlight_profile",
				show: true
			}, {
				title: "index.highlights.100",
				url: "faq.html",
				position: 7,
				highlightClass: "highlight__bg highlight_faq",
				show: true
			}
		]
	}

	openProfileModal() {
		eventBus.emit("profileModal")
	}
}
